import BiltyCreateGeneratingForm from '../../components/app/forms/BiltyCreateGeneratingForm';
import BiltyTruckDetailsModal from '../../components/app/modals/BiltyTruckDetailsModal';
import { useEffect, useState } from 'react';
import { Separator } from '../../components/ui/separator';
import { ChevronRight, Plus } from 'lucide-react';
import { Input } from '../../components/ui/input';
import BiltyCreateConsignmentDetailsCard from '../../components/app/cards/BiltyCreateConsignmentDetailsCard';
import { Link } from 'react-router-dom';
import BiltyInvoiceDetailsForm from '../../components/app/forms/BiltyInvoiceDetailsForm';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Button } from '../../components/ui/button';
import { setBiltyCommission, setLrBiltyNumber } from '../../store/CreateBiltySlice';
import { BiltyDataType } from '../../lib/DataType/BiltyDataType';
import { toast } from 'react-toastify';
import { CreateBiltyService, GetBiltyNumber } from '../../lib/Service/BiltyService';
import { ErrorHandler, IsExpiryDateValid } from '../../lib/Utils/Common';
import BiltyTruckDetailsCard from '../../components/app/cards/bilty-truck-details-card';

const CreateBiltyPage = () => {
    const {
        consignor_consignee_info,
        bilty_commission,
        date,
        driver_name,
        driver_number,
        e_way_bill_number,
        expiry_date,
        fields,
        fleet_owner,
        fleet_owner_number,
        from_city_id,
        gst_number,
        invoice_date,
        invoice_generation_date,
        invoice_number,
        license_number,
        lr_bilty_number,
        order_id,
        remark,
        to_city_id,
        vehicle_number
    } = useSelector((state: RootState) => state.createBilty);
    const dispatch = useDispatch();
    const [showTruckDetailsModal, setShowTruckDetailsModal] = useState<boolean>(false);

    const toggleTruckDetailsModal = () => {
        setShowTruckDetailsModal((prev) => !prev);
    };

    useEffect(() => {
        GetBiltyNumber()
            .then((res) => {
                if (res.status) {
                    console.log('res.status', res);
                    dispatch(setLrBiltyNumber(res.data.lr_bilty_number));
                } else {
                    ErrorHandler(res.data as any);
                }
            })
            .catch(ErrorHandler);
    }, [dispatch]);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (consignor_consignee_info.length <= 0) {
            toast.error('Please add at least one Consignment Details');
            return;
        }
        if (!IsExpiryDateValid(invoice_generation_date, expiry_date)) {
            toast.error('The expiry date must be a date after invoice generation date.');
            return;
        }
        const reqData: BiltyDataType = {
            bilty_commission,
            consignor_consignee_info,
            date,
            driver_name,
            driver_number,
            e_way_bill_number,
            expiry_date,
            fields,
            fleet_owner,
            fleet_owner_number,
            from_city_id,
            gst_number,
            invoice_date,
            invoice_generation_date,
            invoice_number,
            license_number,
            lr_bilty_number,
            order_id,
            remark,
            to_city_id,
            vehicle_number
        };
        console.log(reqData.consignor_consignee_info);
        CreateBiltyService(reqData)
            .then((data) => {
                if (data.status) {
                    toast.success(data.message, {
                        position: 'top-right',
                        theme: 'colored'
                    });
                    window.location.href = '/bilty';
                    // console.log("data", data);
                    // navigate("/bilty");
                }
            })
            .catch(ErrorHandler);
    };

    // add fetching logic here

    const inputClasses: string = 'w-full border border-neutral-200 text-neutral-800';

    return (
        <div className="w-full px-5 mb-10">
            <div>
                <h3 className="font-semibold sm:text-lg">Generating</h3>
            </div>
            <form onSubmit={onSubmit}>
                <div className="pt-3 w-full max-w-xl">
                    <BiltyCreateGeneratingForm />
                </div>
                <div className="my-4">
                    <Separator />
                </div>
                <div className="space-y-3 text-sm sm:text-base w-full">
                    <div>
                        <div
                            className="w-full cursor-pointer border border-neutral-200 rounded-md px-3 py-2 max-w-2xl "
                            onClick={toggleTruckDetailsModal}
                        >
                            <div className="flex items-center justify-between gap-2 text-sm sm:text-base">
                                <p className="font-medium">Truck Details</p>
                                {/* {truckDetails ? (
                                    <ChevronDown
                                        size={16}
                                        className="text-neutral-600"
                                    />
                                ) : ( */}
                                <ChevronRight
                                    size={16}
                                    className="text-neutral-600"
                                />
                                {/* )} */}
                            </div>
                            {(driver_name ||
                                driver_number ||
                                vehicle_number ||
                                fleet_owner ||
                                fleet_owner_number) && (
                                <BiltyTruckDetailsCard
                                    className="border-none text-xs px-0 shadow-none"
                                    vehicleNumber={vehicle_number}
                                    driverName={driver_name}
                                    driverNumber={driver_number}
                                    gstNumber={gst_number}
                                    fleetMobile={fleet_owner_number}
                                    licenseNumber={license_number}
                                    fleetOwner={fleet_owner}
                                />
                            )}
                        </div>
                    </div>
                    <div className=" border border-neutral-200 w-full rounded-md px-3 py-2 max-w-2xl flex flex-col gap-2 text-sm sm:text-base">
                        <Link
                            to="/bilty/create/consigment"
                            className="flex justify-between gap-3"
                        >
                            <p className="font-medium text-sm sm:text-base truncate">
                                Consignment Details
                            </p>
                            <div className="flex items-center gap-2">
                                <div className="px-1 py-0.5 rounded-sm text-xs flex bg-blue-200 items-center">
                                    <Plus
                                        size={16}
                                        className="text-neutral-600 "
                                    />
                                    <p className="text-xs ">Consignor & Consignee</p>
                                </div>
                                <ChevronRight
                                    size={16}
                                    className="text-neutral-600"
                                />
                            </div>
                        </Link>
                        {/* can make changes in grid here if want to show more than one  */}
                        {consignor_consignee_info.length > 0 && (
                            <div className="px-3 py-2 gap-3 flex overflow-x-auto whitespace-nowrap">
                                {consignor_consignee_info.map((item, i) => {
                                    return (
                                        <BiltyCreateConsignmentDetailsCard
                                            consignor={item.consignor_name}
                                            cosignee={item.consignee_name}
                                            remaining={item.remaining_amount}
                                            totalItems={item.item.length}
                                            key={i}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-full my-4 max-w-md pr-2">
                    <h3 className="font-semibold sm:text-lg my-3">Bilty Commission</h3>
                    <Input
                        type="number"
                        name="biltyPrice"
                        placeholder="Bilty Price"
                        className={inputClasses}
                        value={bilty_commission}
                        onChange={(e) => dispatch(setBiltyCommission(e.target.value))}
                    />
                </div>
                <div className="w-full py-4">
                    <BiltyInvoiceDetailsForm />
                </div>
                <div className="w-full flex justify-center items-center">
                    <Button
                        className="bg-cardinal-600 hover:bg-cardinal-600 text-sm sm:text-base w-full max-w-[16rem]"
                        type="submit"
                    >
                        SUBMIT
                    </Button>
                </div>
            </form>
            <BiltyTruckDetailsModal
                isOpen={showTruckDetailsModal}
                onClose={toggleTruckDetailsModal}
            />
            {/*
      {itemDetails.map((item, index) => (
        <ItemDetailsCard key={index} {...item} />
      ))} */}
        </div>
    );
};

export default CreateBiltyPage;
