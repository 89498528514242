import { twMerge } from "tailwind-merge";

export interface BiltyTruckDetailsCardProps {
  vehicleNumber: string;
  driverName: string;
  driverNumber: string;
  licenseNumber: string;
  fleetOwner: string;
  fleetMobile: string;
  gstNumber: string;
  className?: string;
}

export default function BiltyTruckDetailsCard({
  ...props
}: BiltyTruckDetailsCardProps) {
  return (
    <div className={twMerge("p-3 border rounded-md lg:px-0 gap-5 grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-between text-xs ", props.className)}>
      <div className=" flex flex-col lg:items-center">
        <p className="text-neutral-400 text-xs">Vehicle Number</p>
        <p className="font-semibold">{props.vehicleNumber}</p>
      </div>
      <div className=" flex flex-col items-end lg:items-center">
        <p className="text-neutral-400 text-xs">Driver Name</p>
        <p className="font-semibold">{props.driverName}</p>
      </div>
      <div className=" flex flex-col lg:items-center">
        <p className="text-neutral-400 text-xs">Driver Number</p>
        <p className="font-semibold">{props.driverNumber}</p>
      </div>
      <div className=" flex flex-col items-end lg:items-center">
        <p className="text-neutral-400 text-xs ">License Number</p>
        <p className="font-semibold">{props.licenseNumber}</p>
      </div>
      <div className=" flex flex-col lg:items-center">
        <p className="text-neutral-400 text-xs">Feet Owner</p>
        <p className="font-semibold">{props.fleetOwner}</p>
      </div>
      <div className=" flex flex-col items-end lg:items-center">
        <p className="text-neutral-400 text-xs">GST Number</p>
        <p className="font-semibold">{props.gstNumber}</p>
      </div>
      <div className=" flex flex-col lg:items-center">
        <p className="text-neutral-400 text-xs">Fleet Moble</p>
        <p className="font-semibold">{props.fleetMobile}</p>
      </div>
    </div>
  );
}
