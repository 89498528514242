import moment from "moment";

export interface BiltyProgressDetailsBarProps {
  orderId: string;
  biltyStatus: string;
  from: string;
  to: string;
  biltyDate: string | Date;
}

export default function BiltyProgressBarCard({
  ...props
}: BiltyProgressDetailsBarProps) {
  return (
    <div className="pb-3 rounded-lg flex flex-col justify-between gap-2 text-xs sm:text-sm w-full max-w-[38rem]">
      <div className="flex w-full justify-between gap-2 items-center">
        <div>
          <p>
            <span className="text-neutral-400">Order Id:</span> #{props.orderId}
          </p>
        </div>
        <div>
          <p className="text-neurtal-600 font-medium">
            <span className="text-neutral-400 font-normal">Bilty Date:</span>{" "}
            {moment(props.biltyDate).format("DD MMM YYYY")}
          </p>
        </div>
      </div>
      <div className="flex flex-col">
        {/* <div className="relative">
          <img src="/home/bilty-card.svg" alt="bilty" className="w-full" /> */}
          {/* <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <p className="bg-purple-600 rounded-md py-0.5 text-xs text-white px-2">
              {props.biltyStatus}
            </p>
          </div> */}
        {/* </div> */}
        <div className="flex items-center">
          <p className="bg-blue-500 text-white px-5 py-1 text-xs rounded-full ">{props.from}</p>
          <div className="border-b-[2.5px] border-neutral-300 border-dashed flex-1"/>
          <p className="bg-green-500 text-white px-5 py-1 text-xs rounded-full">{props.to}</p>
        </div>
      </div>
    </div>
  );
}
