import React, { useState } from "react";
import { Input } from "../../ui/input";
import { ErrorHandler, ValidateGSTNumber, ValidateNumber } from "../../../lib/Utils/Common";
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { GetCompany } from "../../../lib/Service/BiltyService";
import { CompanyDataType } from "../../../lib/DataType/BiltyDataType";

export default function BiltyConsignorConsigneeForm() {
  return (
    <div className="my-4 grid sm:grid-cols-1 gap-3">
      <ConsignorForm />
      <ConsigneeForm />
    </div>
  );
}

const ConsigneeForm: React.FC = () => {
  const [gst, setGst] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<string>("")
  const [company, setCompany] = useState<CompanyDataType[]>([])
  const [consignee, setConsignee] = useState<string>("")


  const searchCompany = (event: AutoCompleteCompleteEvent) => {
    GetCompany(`search=${event.query}`)
      .then((res) => {
        if (res.status) {
          setCompany(res.data.data);
        }
      })
      .catch(ErrorHandler);
  }

  const onSelect = (e: AutoCompleteChangeEvent) => {
    setConsignee(e.value);

    const findData = company.find((it) => it.name === e.value);
    if (findData) {
      console.log('findData', findData)
      setGst(findData.gst_number);
      setPhone(findData.phone)
      setAddress(findData.address || "")
    }
  };

  const inputClasses: string =
    "w-full  border border-neutral-200 text-neutral-800";

  return (
    <div className="space-y-2 w-full">
      <h3 className="font-semibold sm:text-lg">Consignee</h3>
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-2">
        {/* <Input
          type="text"
          required
          name="consignee_name"
          placeholder="Consignee"
          className={inputClasses}
        /> */}

        <AutoComplete
          value={consignee}
          suggestions={company.map((it) => it.name)}
          completeMethod={searchCompany}
          onChange={onSelect}
          loadingIcon={() => <></>}
          name="consignee_name"
          inputClassName="pl-3 w-full"
          placeholder="Consignee"
          className={`h-9 w-full border rounded-md text-sm text-neutral-800 border-neutral-200}`}
        />

        <div>
          <Input
            type="text"
            // required
            value={phone}
            name="consignee_mobile_no"
            placeholder="Mobile Number"
            className={`${inputClasses} ${!ValidateNumber(phone) ? "border-red-500" : "border-neutral-200"}`}
            onChange={(e) => setPhone(e.target.value)}
          />
          {!ValidateNumber(phone) && (
            <small id="username-help" className="text-red-500">
              please enter a valid 10 digit mobile number
            </small>
          )}
        </div>

        <div>
          <Input
            type="text"
            // required
            value={gst}
            name="consignee_gst_number"
            onChange={(e) => setGst(e.target.value)}
            placeholder="GST Number"
            className={`${inputClasses} ${!ValidateGSTNumber(gst) ? "border-red-500" : "border-neutral-200"}`}
          />
          {!ValidateGSTNumber(gst) && (
            <small id="username-help" className="text-red-500">
              please enter a valid GST number
            </small>
          )}
        </div>
        <Input
          type="text"
          // required
          value={address}
          name="consignee_address"
          placeholder="Address"
          onChange={(e)=> setAddress(e.target.value)}
          className={inputClasses}
        />
      </div>
    </div>
  );
};

const ConsignorForm: React.FC = () => {
  const [gst, setGst] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<string>("")
  const [company, setCompany] = useState<CompanyDataType[]>([])
  const [consignor, setConsignor] = useState<string>("")


  const searchCompany = (event: AutoCompleteCompleteEvent) => {
    GetCompany(`search=${event.query}`)
      .then((res) => {
        if (res.status) {
          setCompany(res.data.data);
        }
      })
      .catch(ErrorHandler);
  }

  const onSelect = (e: AutoCompleteChangeEvent) => {
    setConsignor(e.value);

    const findData = company.find((it) => it.name === e.value);
    if (findData) {
      console.log('findData', findData)
      setGst(findData.gst_number);
      setPhone(findData.phone)
      setAddress(findData.address || "")
    }
  };


  const inputClasses: string =
    "w-full  border border-neutral-200 text-neutral-800";

  return (
    <div className="space-y-2 w-full">
      <h3 className="font-semibold sm:text-lg">Consignor</h3>
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-2">
        {/* <Input
          type="text"
          required
          name="consignor_name"
          placeholder="Consignor"
          className={inputClasses}
        /> */}
        <AutoComplete
          value={consignor}
          suggestions={company.map((it) => it.name)}
          completeMethod={searchCompany}
          onChange={onSelect}
          loadingIcon={() => <></>}
          name="consignor_name"
          inputClassName="pl-3 w-full"
          placeholder="Consignor"
          className={`h-9 w-full border rounded-md text-sm text-neutral-800 border-neutral-200}`}
        />

        <div>
          <Input
            type="text"
            value={phone}
            // required
            name="consignor_mobile_no"
            placeholder="Mobile Number"
            className={`${inputClasses} ${!ValidateNumber(phone) ? "border-red-500" : "border-neutral-200"}`}
            onChange={(e) => setPhone(e.target.value)}
          />
          {!ValidateNumber(phone) && (
            <small id="username-help" className="text-red-500">
              please enter a valid 10 digit mobile number
            </small>
          )}
        </div>

        <div>
          <Input
            type="text"
            value={gst}
            // required
            name="consignor_gst_number"
            placeholder="GST Number"
            onChange={(e) => setGst(e.target.value)}
            className={`${inputClasses} ${!ValidateGSTNumber(gst) ? "border-red-500" : "border-neutral-200"}`}
          />
          {!ValidateGSTNumber(gst) && (
            <small id="username-help" className="text-red-500">
              please enter a valid GST number
            </small>
          )}
        </div>

        <Input
          type="text"
          value={address}
          // required
          name="consignor_address"
          placeholder="Address"
          onChange={(e)=> setAddress(e.target.value)}
          className={inputClasses}
        />
      </div>
    </div>
  );
};
