import React, { useEffect, useState } from "react";
import BiltyTruckDetailsCard from "../../components/app/cards/bilty-truck-details-card";
import BiltyProgressBarCard from "../../components/app/cards/bilty-progress-bar-card";
import BiltyInvoiceDetailsCard from "../../components/app/cards/bilty-invoice-details-card";
import BiltyConsignorConsigneeCard from "../../components/app/cards/bilty-consignor-consignee-card";
import { BiltyDetailsDataType } from "../../lib/DataType/BiltyDataType";
import { useParams } from "react-router-dom";
import { GetBiltyDetailsService } from "../../lib/Service/BiltyService";
import { ErrorHandler } from "../../lib/Utils/Common";
import { DownloadPDF } from "../../lib/Utils/DonloadPdf";

const BiltyDetailsPage: React.FC = () => {
  const { id } = useParams();

  const [biltyDetails, setBiltyDetails] = useState<BiltyDetailsDataType | null>(
    null,
  );

  useEffect(() => {
    if (id) {
      GetBiltyDetailsService(id)
        .then((data) => {
          if (data.status) {
            setBiltyDetails(data.data.data);
          }
        })
        .catch(ErrorHandler);
    }
  }, [id]);

  if (biltyDetails === null) {
    return null;
  }

  return (
    <div className="w-full px-4 mb-10">
      <div>
        <div className="flex justify-between items-center">
          <h3 className="sm:text-lg font-bold">
            {/* add that fetched bilty lr number here then */}
            LR Bilty No: {biltyDetails.lr_bilty_number}
          </h3>
          <div className="pr-3">
            <button
              className="gap-1 whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&amp;_svg]:pointer-events-none [&amp;_svg]:size-4 [&amp;_svg]:shrink-0 h-9 rounded-full p-2.5 flex items-center justify-center text-white bg-green-600 hover:bg-green-600 shadow-none"
              onClick={() => {
                DownloadPDF(`bilty/${id}/download`);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-arrow-down-to-line"
              >
                <path d="M12 17V3"></path>
                <path d="m6 11 6 6 6-6"></path>
                <path d="M19 21H5"></path>
              </svg>
            </button>
          </div>
        </div>
        <div className="w-full flex mb-4">
          <BiltyProgressBarCard
            biltyDate={biltyDetails.date}
            biltyStatus={biltyDetails.status}
            from={biltyDetails.from_city}
            orderId={biltyDetails.order_id}
            to={biltyDetails.to_city}
          />
        </div>
      </div>
      
      <div className="grid gap-5 max-w-4xl">
        <div>
          <h3 className="sm:text-lg font-bold">Truck Details</h3>
          <div className="w-full mb-4 mt-1">
            <BiltyTruckDetailsCard
              driverName={biltyDetails.truck_details.driver_name}
              driverNumber={biltyDetails.truck_details.driver_number}
              fleetMobile={""}
              fleetOwner={biltyDetails.truck_details.fleet_owner}
              gstNumber={biltyDetails.truck_details.gst_number}
              licenseNumber={biltyDetails.truck_details.license_number}
              vehicleNumber={biltyDetails.truck_details.vehicle_number}
            />
          </div>
        </div>
        <div>
          <h3 className="sm:text-lg font-bold">Consignment Details</h3>
          <div>
            {biltyDetails.consignment_details.map((consigneeConsignors, i) => {
              return (
                <div className="w-full space-y-3" key={i}>
                  {/* the props are not passed on here fetch the data inside the card component and then pass it into it's two sub cards component */}
                  <BiltyConsignorConsigneeCard
                    consigneeData={{
                      address: consigneeConsignors.consignee_address,
                      consignee: consigneeConsignors.consignee_name,
                      gstNumber: consigneeConsignors.consignee_gst_number,
                      mobile: consigneeConsignors.consignee_name,
                    }}
                    consignorData={{
                      address: consigneeConsignors.consignor_address,
                      consignor: consigneeConsignors.consignor_name,
                      gstNumber: consigneeConsignors.consignor_gst_number,
                      mobile: consigneeConsignors.consignor_name,
                    }}
                    items={consigneeConsignors.items}
                    freightDetails={{
                      advance_amount: Number(
                        consigneeConsignors.advance_amount,
                      ),
                      amount: Number(consigneeConsignors.amount),
                      freight: consigneeConsignors.freight,
                      pricePerUnit: 0,
                      remaining_amount: Number(
                        consigneeConsignors.remaining_amount,
                      ),
                    }}
                  />

                  {/* <div className="space-y-3">
                          <h3 className="font-semibold">Items Details</h3>
                          <div className="grid lg:grid-cols-2 gap-3">
                            {consigneeConsignors.items.map((item, index) => (
                              <BiltyItemDetailsCard
                                key={index}
                                amount={item.amount}
                                capacity={item.capacity}
                                item={item.item}
                                packaging_type={item.packaging_type}
                                qty={item.qty}
                                weight={item.weight}
                              />
                            ))}
                          </div>
                        </div>
                        <h3 className="font-semibold">Fright Details</h3>
                        <BiltyFrightDetailsCard
                          advance={Number(consigneeConsignors.advance_amount)}
                          amount={Number(consigneeConsignors.amount)}
                          frightType={consigneeConsignors.freight}
                          pricePerUnit={0}
                          remaining={Number(
                            consigneeConsignors.remaining_amount,
                          )}
                        /> */}
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <h3 className="sm:text-lg font-bold">Invoice & E-Way Bill Details</h3>
          <div className="w-full mb-4 mt-1">
            <BiltyInvoiceDetailsCard
              billNumber={biltyDetails.e_way_bill_number}
              expiryDate={biltyDetails.expiry_date}
              generationDate={biltyDetails.invoice_generation_date}
              invoiceDate={biltyDetails.invoice_date}
              invoiceNumber={biltyDetails.invoice_number}
            />
          </div>
        </div>
        <div className="grid md:grid-cols-5 md:flex-row gap-5">
          <div className="md:col-span-2">
            <div className=" border p-3 h-fit md:max-w-xs rounded-md">
              <h3 className="sm:text-lg font-bold">Bilty Commission</h3>
              <p className="text-neutral-400 pt-1 text-xs">Bilty Price</p>
              <div className="font-semibold rounded-md">
                ₹{biltyDetails.bilty_commission}
              </div>
            </div>
          </div>
          <div className="flex-1 md:col-span-3 border rounded-md p-3">
            <h3 className="sm:text-lg font-bold">Total Consignment</h3>
            <div className=" flex flex-col gap-1 rounded-md p-2 text-xs sm:text-sm">
              <div className="flex gap-2 justify-between">
                <div className="text-xs sm:text-sm">
                  <p className="font-bold">Total</p>
                  <p className="text-xs"> (All Consignment)</p>
                </div>
                <p className="font-normal text-xs sm:text-sm">₹{biltyDetails.total_amount}</p>
              </div>
              <div className="flex gap-2 justify-between">
              <div className="text-xs sm:text-sm">
                  <p className="font-bold">Advance</p>
                  <p className="text-xs"> (All Consignment)</p>
                </div>
                <p className="font-normal text-xs sm:text-sm">₹ {biltyDetails.total_advance}</p>
              </div>
              <div className="flex gap-2 justify-between">
              <div className="text-xs sm:text-sm">
                  <p className="font-bold">Remaining</p>
                  <p className="text-xs"> (All Consignment)</p>
                </div>
                <p className="font-normal text-xs sm:text-sm">₹ {biltyDetails.total_remaining}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BiltyDetailsPage;
