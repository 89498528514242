import { ResDataType, ResListDataType } from "../DataType/ApiCommon";
import {
  BiltyDataType,
  BiltyDetailsDataType,
  BiltyListDataType,
  BiltySettingDataDatType,
  CompanyDataType,
  DriverDataType,
  FleetOwnersDataType,
} from "../DataType/BiltyDataType";
import { TRANSPORTER_URL } from "../Utils/EnvVariables";
import HttpClient from "../Utils/HttpClient";

export const BiltySettingUpdate = (
  request: BiltySettingDataDatType,
): Promise<ResDataType<BiltySettingDataDatType>> => {
  return HttpClient.post(`${TRANSPORTER_URL}/bilty/bilty-setting`, request);
};

export const BiltySettingGet = (): Promise<
  ResDataType<BiltySettingDataDatType>
> => {
  return HttpClient.get(`${TRANSPORTER_URL}/bilty/bilty-setting`);
};

export const CreateBiltyService = (
  request: BiltyDataType,
): Promise<ResDataType<BiltyDataType>> => {
  return HttpClient.post(`${TRANSPORTER_URL}/bilty/create`, request);
};

export const GetBiltyListService = (
  pramitar?: string,
): Promise<ResListDataType<BiltyListDataType[]>> => {
  return HttpClient.get(`${TRANSPORTER_URL}/bilty/list?${pramitar}`);
};

export const GetBiltyDetailsService = (
  id: string | number,
): Promise<ResListDataType<BiltyDetailsDataType>> => {
  return HttpClient.get(`${TRANSPORTER_URL}/bilty/${id}/show`);
};

export const GetBiltyNumber = (): Promise<
  ResDataType<{ lr_bilty_number: string }>
> => {
  return HttpClient.get(`${TRANSPORTER_URL}/bilty/get-current-bilty-num`);
};

export const GetFleetOwners = (
  pramitar?: string,
): Promise<ResListDataType<FleetOwnersDataType[]>> => {
  return HttpClient.get(
    `${TRANSPORTER_URL}/bilty/saved-fleet-owners?${pramitar}`,
  );
};

export const GetDrivers = ( pramitar?: string): Promise<ResListDataType<DriverDataType[]>> => {
  return HttpClient.get(`${TRANSPORTER_URL}/bilty/saved-drivers?${pramitar}`)
}

export const GetCompany = ( pramitar?: string): Promise<ResListDataType<CompanyDataType[]>> => {
  return HttpClient.get(`${TRANSPORTER_URL}/company/list?${pramitar}`)
}
