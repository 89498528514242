import moment from "moment";

export interface BiltyInvoiceDetailsCardProps {
  invoiceNumber: string;
  invoiceDate: string | Date;
  billNumber: string;
  generationDate: string | Date;
  expiryDate: string | Date;
}

export default function BiltyInvoiceDetailsCard({
  ...props
}: BiltyInvoiceDetailsCardProps) {
  return (
    <div className="p-3 border rounded-md lg:px-0 gap-5 grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-between text-xs ">
        <div className="flex flex-col lg:items-center">
          <p className="text-neutral-400 text-xs">Invoice Number</p>
          <p className="font-semibold">{props.invoiceNumber}</p>
        </div>
        <div className="flex flex-col items-end lg:items-center">
          <p className="text-neutral-400 text-xs">Invoice Date</p>
          <p className="font-semibold">
            {moment(props.invoiceDate).format("DD-MMM-YYYY")}
          </p>
        </div>
        <div className="flex flex-col lg:items-center">
          <p className="text-neutral-400 text-xs">Generation Date</p>
          <p className="font-semibold">
            {moment(props.generationDate).format("DD-MMM-YYYY")}
          </p>
        </div>
        <div className="flex flex-col items-end lg:items-center">
          <p className="text-neutral-400 text-xs">Expiry Date</p>
          <p className="font-semibold">
            {moment(props.expiryDate).format("DD-MMM-YYYY")}
          </p>
        </div>
        <div className="flex flex-col lg:items-center">
          <p className="text-neutral-400 text-xs">E-Way Bill Number</p>
          <p className="font-semibold">{props.invoiceNumber}</p>
        </div>
    </div>
  );
}
