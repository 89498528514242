import React, { useEffect, useState } from "react";
import { Input } from "../../ui/input";

interface Props {
  amount: number
}

const BiltyFrightDetailsForm: React.FC<Props> = ({ amount }) => {
  const [totalAmount, setTotalAmount] = useState<number | string>(amount);
  const [advanceAmount, setAdvanceAmount] = useState<number | string>("");
  const [remainingAmount, setRemainingAmount] = useState<number | string>("");
  const [freight, setFreight] = useState<string>("1");


  useEffect(() => {
    setRemainingAmount(Number(totalAmount) - Number(advanceAmount));
  }, [totalAmount, advanceAmount]);

  useEffect(() => {
    setTotalAmount(amount)
  }, [amount])

  const inputClasses: string =
    "w-full  border border-neutral-200 text-neutral-800";

  return (
    <div className="w-full max-w-[56rem] space-y-4">
      <h3 className="font-semibold text-base">Freight Details</h3>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="space-y-4">
          <Input
            type="number"
            required
            name="total_amount"
            placeholder="Total Amount"
            className={inputClasses}
            value={totalAmount || ""}
            onChange={(e) => setTotalAmount(Number(e.target.value))}
          />
          <div className="flex items-center gap-4">
            <label className="flex items-center gap-2 cursor-pointer">
              <input
                type="radio"
                name="freight"
                value="1"
                className="w-4 h-4 text-blue-600 cursor-pointer"
                onClick={() => setFreight("1")}
                defaultChecked
              />
              <span className="text-sm text-gray-700">To Pay</span>
            </label>
            <label className="flex items-center gap-2 cursor-pointer">
              <input
                type="radio"
                name="freight"
                value="2"
                className="w-4 h-4 text-blue-600 cursor-pointer"
                onClick={() => setFreight("2")}
              />
              <span className="text-sm text-gray-700">Advance</span>
            </label>
          </div>
          {freight === "1" && (
            <>
              <Input
                type="number"
                required={freight === "1"}
                name="advance_amount"
                placeholder="Advance"
                className={inputClasses}
                value={advanceAmount || ""}
                onChange={(e) => setAdvanceAmount(Number(e.target.value))}
              />
              <Input
                type="number"
                required
                name="remaining_amount"
                placeholder="Remaining"
                value={remainingAmount || ""}
                onChange={(e) => setRemainingAmount(Number(e.target.value))}
                className={inputClasses}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BiltyFrightDetailsForm;
