import React from "react";
import { Input } from "../../ui/input";
import InputDatePicker from "../date-picker";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  setEWayBillNumber,
  setExpiryDate,
  setInvoiceDate,
  setInvoiceGenerationDate,
  setInvoiceNumber,
} from "../../../store/CreateBiltySlice";
import moment from "moment";

const BiltyInvoiceDetailsForm: React.FC = () => {
  const {
    invoice_number,
    invoice_date,
    invoice_generation_date,
    expiry_date,
    e_way_bill_number,
  } = useSelector((state: RootState) => state.createBilty);
  const dispatch = useDispatch();

  const inputClasses: string =
    "w-full border border-neutral-200 text-neutral-800";

  return (
    <div className="w-full max-w-[56rem] space-y-4">
      <h3 className="font-semibold text-base">Invoice & E-Way Bill Details</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="space-y-4">
          <Input
            type="text"
            required
            name="invoiceNumber"
            placeholder="Invoice Number"
            className={inputClasses}
            value={invoice_number}
            onChange={(e) => dispatch(setInvoiceNumber(e.target.value))}
          />
          <Input
            type="number"
            required
            name="billNumber"
            placeholder="E-Way Bill Number"
            className={inputClasses}
            value={e_way_bill_number}
            onChange={(e) => dispatch(setEWayBillNumber(e.target.value))}
          />
          <InputDatePicker
            name="expiryDate"
            placeholder="Expiry Date"
            date={expiry_date !== "" ? new Date(expiry_date) : undefined}
            onChange={(e) =>
              dispatch(setExpiryDate(moment(e).format("YYYY/MM/DD")))
            }
          />
        </div>
        <div className="space-y-4">
          <InputDatePicker
            name="invoiceDate"
            placeholder="Invoice Date"
            date={invoice_date !== "" ? new Date(invoice_date) : undefined}
            onChange={(e) =>
              dispatch(setInvoiceDate(moment(e).format("YYYY/MM/DD")))
            }
          />
          <InputDatePicker
            name="generationDate"
            placeholder="Generation Date"
            date={
              invoice_generation_date !== ""
                ? new Date(invoice_generation_date)
                : undefined
            }
            onChange={(e) => {
              dispatch(
                setInvoiceGenerationDate(moment(e).format("YYYY/MM/DD")),
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BiltyInvoiceDetailsForm;
