import React, { useState } from "react";
import { GetCities } from "../../../lib/Service/MasterService";
import { CitieDataType } from "../../../lib/DataType/MasterDataType";
import { ErrorHandler } from "../../../lib/Utils/Common";
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";
import { CircleX } from "lucide-react";

type Props = {
  onValueChange: (data: string) => void;
  value: string;
  name?: string;
  required?: boolean;
  placeholder?: string;
  isError?: boolean;
  errorTest?: string;
};

const CitySelectInput: React.FC<Props> = ({
  onValueChange,
  value,
  name = "city",
  required = false,
  placeholder = "City",
  errorTest = "Please enter correct details",
  isError,
}) => {
  const [citiesList, setCitiesList] = useState<CitieDataType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectSities, setSelectSities] = useState<string>("");

  const onSearch = (event: AutoCompleteCompleteEvent) => {
    setLoading(true);
    GetCities(`pagination=10&page=1&search=${event.query}`)
      .then((data) => {
        if (data.status) {
          setCitiesList(
            data.data.data.filter((it) => it.city_with_state !== null),
          );
          setLoading(false);
        }
      })
      .catch(ErrorHandler);
  };

  const onChange = (e: AutoCompleteChangeEvent) => {
    const filter = citiesList.find((it) => it.city_with_state === e.value);
    if (filter) {
      onValueChange(filter.id.toString());
    }
    setSelectSities(e.value);
    setLoading(false);
  };
  return (
    <div className="w-full">
      <div className="relative w-full">
        <div className="card p-fluid">
          <AutoComplete
            value={selectSities}
            suggestions={citiesList.map((it) => it.city_with_state)}
            completeMethod={onSearch}
            onChange={onChange}
            required={required}
            inputClassName="pl-3"
            forceSelection
            name={name}
            placeholder={placeholder}
            className={`h-9 w-full bg-neutral-50  border rounded-md text-sm text-neutral-800 ${isError ? "border-red-500" : "border-neutral-200"}`}
          />
        </div>
        {selectSities !== null && !loading && (
          <CircleX
            className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
            onClick={() => setSelectSities("")}
          />
        )}
      </div>
      {isError && (
        <small id="username-help" className="text-red-500 absolute pb-4">
          {errorTest}
        </small>
      )}
    </div>
  );
};

export default CitySelectInput;
