import { ModalProps } from "../../../lib/definitions";
import React, { useEffect, useRef } from "react";
import { Button } from "../../ui/button";
import InputDatePicker from "../date-picker";
import { FormatDate } from "../../../lib/Utils/Common";
import { X } from "lucide-react";

export interface KhataStatementFilterDataType {
  from_date: string;
  to_date: string;
}

const KhataStatementFilterModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const data: KhataStatementFilterDataType = {
      from_date: FormatDate(form.get("dateFrom") as string),
      to_date: FormatDate(form.get("dateTo") as string),
    };
    console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-md animate-in fade-in duration-200 p-5"
        ref={modalRef}
      >
        <div className="flex justify-end pr-5 pt-5">
          <X
            onClick={onClose}
            size={20}
            className=" cursor-pointer text-neutral-700"
          />
        </div>
        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <h3 className="text-center font-bold">Statement Filter</h3>
          <div>
            <InputDatePicker name="dateFrom" placeholder="Date From" />
          </div>
          <div>
            <InputDatePicker name="dateTo" placeholder="Date to" />
          </div>
          <Button
            type="submit"
            className="w-full bg-cardinal-500 hover:bg-cardinal-600 text-white py-3 rounded-lg"
          >
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};
export default KhataStatementFilterModal;
