import { Link } from "react-router-dom";
import BiltyCard from "../components/app/cards/bilty-card";
import React, { useEffect, useState } from "react";
import { GetBiltyListService } from "../lib/Service/BiltyService";
import { BiltyListDataType } from "../lib/DataType/BiltyDataType";
import { ErrorHandler } from "../lib/Utils/Common";

interface AddCreatePostCardDetails {
  src: string;
  title: string;
  height: number;
  link: string;
}

const Homepage: React.FC = () => {
  const [biltyList, setBiltyList] = useState<BiltyListDataType[]>([]);

  const addCreatePostCardDetails: AddCreatePostCardDetails[] = [
    {
      src: "/home/create-bilty.svg",
      title: "Create Bilty",
      height: 47,
      link: "/bilty/create",
    },
    {
      src: "/home/so-note0.svg",
      title: "Add Khata",
      height: 47,
      link: "/khata",
    },
  ];

  useEffect(() => {
    GetBiltyListService()
      .then((data) => {
        console.log("data", data);
        if (data.status) {
          setBiltyList(data.data.data);
        }
      })
      .catch(ErrorHandler);
  }, []);

  return (
    <div className="w-full space-y-3 mb-10">
      <div className="grid sm:grid-cols-2 items-center w-full gap-4 px-5">
        {addCreatePostCardDetails.map((card: AddCreatePostCardDetails) => (
          <AddCreatePostCard {...card} key={card.src} />
        ))}
      </div>
      {/* <div className="space-y-4 px-5">
        <h3 className="font-bold text-lg sm:text-2xl">Recent Loads</h3>
        <div className="grid gap-3 lg:grid-cols-2 xl:grid-cols-3">
          {recentLoadCardsData.map((load) => (
            <RecentLoadCard {...load} key={load.orderId} />
          ))}
        </div>
      </div> */}
      <div className="space-y-4 px-5 pt-6">
        <h3 className="font-bold text-lg sm:text-2xl">Bilty</h3>
        <div className="grid gap-5 md:grid-cols-2">
          {biltyList.map((bilty, i) => (
            <BiltyCard
              key={i}
              date={new Date(bilty.date)}
              from={bilty.from_city}
              lrNumber={bilty.lr_bilty_number}
              items={Number(bilty.order_count)}
              status={bilty.status}
              to={bilty.to_city}
              id={bilty.id}
              vehicleNumber={bilty.vehicle_number}
              consignee_consignors={bilty.consignee_consignors}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Homepage;

function AddCreatePostCard({
  src,
  height = 47,
  title,
  link,
}: AddCreatePostCardDetails) {
  return (
    <Link
      to={link}
      className="px-5 py-2 shadow-custom rounded-lg w-full h-full flex items-center justify-center"
    >
      <div className="flex flex-col items-center justify-between h-full w-full">
        <div className="flex-1 flex items-center justify-center">
          <img
            src={src}
            className="object-contain h-10 sm:h-16 md:h-16"
            alt={title}
          />
        </div>
        <p className="text-center font-bold mt-4">{title}</p>
      </div>
    </Link>
  );
}
