import { ChevronDown, ChevronRight } from "lucide-react";
import React, { useState } from "react";
import { BiltyItemDataType } from "../../../lib/DataType/BiltyDataType";
import BiltyItemDetailsCard from "./BiltyItemDetailsCard";
import BiltyFrightDetailsCard from "./BiltyFrightDetailsCard";

export interface ConsignorDetails {
  consignor: string;
  gstNumber: string;
  mobile: string;
  address: string; // this depened how roadlyne is storing address in the backend / db
}

export interface ConsigneeDetails {
  consignee: string;
  gstNumber: string;
  mobile: string;
  address: string;
}

export interface FreightDetails {
  freight: number;
  amount: number;
  advance_amount: number;
  remaining_amount: number;
  consignor_id?: string;
  consignee_id?: string;
  pricePerUnit: number;
}

export interface BiltyConsignorConsigneeCardProps {
  consignorData: ConsignorDetails;
  consigneeData: ConsigneeDetails;
  items: BiltyItemDataType[];
  freightDetails: FreightDetails;
}

const BiltyConsignorConsigneeCard: React.FC<
  BiltyConsignorConsigneeCardProps
> = ({ consigneeData, consignorData, items, freightDetails }) => {
  const [isConsignmentDetailsExpanded, setIsConsignmentDetailsExpanded] =
    useState<boolean>(false);

  const toggleConsignmentDetailsExpanded = () => {
    setIsConsignmentDetailsExpanded((prev) => !prev);
  };
  return (
    <div className="mb-4 mt-1 p-3  border rounded-md">
      <div
        className="grid grid-cols-3 w-full items-center"
        onClick={toggleConsignmentDetailsExpanded}
      >
        <div className="w-full flex items-center justify-center">
          <div className="flex flex-col">
            <p className="text-sm text-neutral-400">Consignor</p>
            <p className="text-xs font-semibold md:text-sm">
              {consignorData.consignor}
            </p>
          </div>
        </div>
        <div className="flex justify-end items-center">
          <div className="flex flex-col justify-center items-center">
            <p className="text-sm text-neutral-400">Consignor</p>
            <p className="text-xs font-semibold md:text-sm">
              {consigneeData.consignee}
            </p>
          </div>
        </div>
        <div className="flex justify-end">
          {isConsignmentDetailsExpanded ? (
            <ChevronDown
              size={16}
              className="text-neutral-600 transform transition-all duration-200 ease-in-out rotate-0"
            />
          ) : (
            <ChevronRight
              size={16}
              className="text-neutral-600 transform transition-all duration-200 ease-in-out -rotate-90"
            />
          )}
        </div>
      </div>
      {isConsignmentDetailsExpanded && (
        <div className="p-3 rounded-md grid xl:grid-cols-2 gap-2 text-xs sm:text-sm">
          <ConsignorDetailsCard {...consignorData} />
          {/* consignee thing goes here */}
          <ConsigneeDetailsCard {...consigneeData} />
          <div className="space-y-3 col-span-2">
            <h3 className="font-semibold">Items Details</h3>
            <div className="grid lg:grid-cols-2 gap-3">
              {items.map((item, index) => (
                <BiltyItemDetailsCard
                  key={index}
                  amount={item.amount}
                  capacity={item.capacity}
                  item={item.item}
                  packaging_type={item.packaging_type}
                  qty={item.qty}
                  weight={item.weight}
                />
              ))}
            </div>
          </div>
          <div className="space-y-3 col-span-2">
            <h3 className="font-semibold">Fright Details</h3>
            <BiltyFrightDetailsCard
              advance={Number(freightDetails.advance_amount)}
              amount={Number(freightDetails.amount)}
              frightType={freightDetails.freight}
              pricePerUnit={0}
              remaining={Number(freightDetails.remaining_amount)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BiltyConsignorConsigneeCard;

function ConsignorDetailsCard({ ...props }: ConsignorDetails) {
  return (
    <div className="grid sm:grid-cols-2 gap-2 justify-between xl:pr-6 text-xs p-2 border-b xl:border-r xl:border-b-0">
      <div>
        <p className="text-neutral-400">Consignor</p>
        <p className="font-semibold ">{props.consignor}</p>
      </div>
      <div>
        <p className="text-neutral-400 sm:text-right">GST Number</p>
        <p className="font-semibold sm:text-right ">{props.gstNumber}</p>
      </div>
      <div>
        <p className="text-neutral-400">Mobile Number</p>
        <p className="font-semibold">{props.mobile}</p>
      </div>
      <div>
        <p className="text-neutral-400 sm:text-right">Address</p>
        <p className="font-semibold text-wrap sm:text-right">{props.address}</p>
      </div>
    </div>
  );
}

function ConsigneeDetailsCard({ ...props }: ConsigneeDetails) {
  return (
    <div className="grid sm:grid-cols-2 gap-2 justify-between text-xs p-2 ">
      <div>
        <p className="text-neutral-400">Consignee</p>
        <p className="font-semibold ">{props.consignee}</p>
      </div>
      <div>
        <p className="text-neutral-400 sm:text-right">GST Number</p>
        <p className="font-semibold sm:text-right ">{props.gstNumber}</p>
      </div>
      <div>
        <p className="text-neutral-400">Mobile Number</p>
        <p className="font-semibold">{props.mobile}</p>
      </div>
      <div>
        <p className="text-neutral-400 sm:text-right">Address</p>
        <p className="font-semibold text-wrap sm:text-right">{props.address}</p>
      </div>
    </div>
  );
}
