/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Input } from "../components/ui/input";
import KhataAmountDetailsCard from "../components/app/cards/KhataAmountDetailsCard";
import { Button } from "../components/ui/button";
import KhataAddAccountModal from "../components/app/modals/KhataAddAccountModal";
import KhataAccountDetailsCard from "../components/app/cards/KhataAccountDetailsCard";
import KhataPaymentStatusCard from "../components/app/cards/khata-payment-status-card";
import clsx from "clsx";
import { Undo2Icon } from "lucide-react";
import {
  GetKataAmountListService,
  GetKataListService,
} from "../lib/Service/KhataService";
import {
  KhataItemDataType,
  KhataPaymentStatus,
  KhataTransactionDataType,
} from "../lib/DataType/KhataDataType";
import { ErrorHandler } from "../lib/Utils/Common";
import KhataHistoryCard from "../components/app/cards/KhataHistoryCard";
import KhataAmountHistoryModal from "../components/app/modals/KhataEditHistoryModal";
import KhataEditAccountDetailsModal from "../components/app/modals/KhataEditAccountDetailsModal";
import KhataStatementFilterModal, {
  KhataStatementFilterDataType,
} from "../components/app/modals/KhataStatementFilterModal";

type KhataAmounModalDataType =
  | {
      isOpen: boolean;
      data: KhataTransactionDataType | null;
      type: number;
      isEdit?: false;
    }
  | {
      isOpen: boolean;
      data: KhataTransactionDataType | null;
      type: number;
      isEdit: true;
      index: number;
    };

const KhataPage = () => {
  const [searchParams, setSearchParams] = useState<string>("");
  const [refash, setRefash] = useState<boolean>(false);
  const [showKhataAmounModal, setShowKhataAmountModal] =
    useState<KhataAmounModalDataType>({
      data: null,
      isOpen: false,
      type: 1,
      isEdit: false,
    });

  const [loading, setLoading] = useState<boolean>(false);
  const [
    showKhataEditAccountDetailsModal,
    setShowKhataEditAccountDetailsModal,
  ] = useState<boolean>(false);

  const [showAddAccountModal, setShowAddAccountModal] =
    useState<boolean>(false);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const [showAccountDetails, setShowAccountDetails] = useState<boolean>(false);

  const [giveAmt, setGiveAmt] = useState<number>(0);
  const [getAmt, setGetAmt] = useState<number>(0);
  const [paymentList, setPaymentList] = useState<KhataItemDataType[]>([]);
  const [accountDetails, setAccountDetails] =
    useState<KhataItemDataType | null>(null);
  const [singlePaymentList, setSinglePaymentList] = useState<
    KhataTransactionDataType[]
  >([]);
  const [paymentListPages, setPaymentListPage] = useState<number>(1);
  const [paymentListTotalPages, setPaymentListTotalPage] = useState<number>(0);

  const [showStatementFilterModal, setShowStatementFilterModal] =
    useState<boolean>(false);
  const [showStatementFilterModalData, setShowStatementFilterModalData] =
    useState<KhataStatementFilterDataType | null>({
      from_date: "",
      to_date: "",
    });

  const toggleStatementFilterModal = () => {
    setShowStatementFilterModal((prev) => !prev);
  };

  useEffect(() => {
    function handleResize() {
      const isSmall = window.innerWidth < 768;
      setIsSmallScreen(isSmall);
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    GetKataListService(
      `search=${searchParams}&pagination=10&page=${paymentListPages}`,
    )
      .then((res) => {
        if (res.status) {
          const data = res.data;
          setGiveAmt(data.give_amt);
          setGetAmt(data.get_amt);
          if (loading) {
            setPaymentList((paymentList) => paymentList.concat(data.data));
            setLoading(false);
          } else {
            setPaymentList(data.data);
          }
          setPaymentListTotalPage(data.pagination.last_page);
          setPaymentListPage(data.pagination.current_page);
        }
      })
      .catch(ErrorHandler);
  }, [refash, searchParams, paymentListPages]);

  useEffect(() => {
    if (accountDetails) {
      const accountDetailsTemp = paymentList.find(
        (it) => it.id === accountDetails?.id,
      );
      if (accountDetailsTemp) {
        setAccountDetails(accountDetailsTemp);
      }
    }
  }, [paymentList, accountDetails]);

  const clickAccount = (item: KhataItemDataType) => {
    setAccountDetails(null);
    setSinglePaymentList([]);
    setShowStatementFilterModalData(null);

    GetKataAmountListService(item.id)
      .then((res) => {
        if (res.status) {
          const data = res.data;
          setAccountDetails(data.khata_account);
          setSinglePaymentList(data.transaction.data);
        }
      })
      .catch(ErrorHandler)
      .finally(() => setShowAccountDetails(true));
  };

  useEffect(() => {
    if (showStatementFilterModalData && accountDetails) {
      GetKataAmountListService(
        accountDetails?.id,
        `from_date=${showStatementFilterModalData.from_date}&to_date=${showStatementFilterModalData.to_date}`,
      )
        .then((res) => {
          if (res.status) {
            const data = res.data;
            setAccountDetails(data.khata_account);
            setSinglePaymentList(data.transaction.data);
          }
        })
        .catch(ErrorHandler)
        .finally(() => setShowAccountDetails(true));
    }
  }, [showStatementFilterModalData]);

  const toggleAddAccountModal = () => {
    setShowAddAccountModal((prev) => !prev);
  };

  const toggleAccountDetails = () => {
    setShowAccountDetails((prev) => !prev);
  };

  const onSubmitPayment = (item: KhataTransactionDataType) => {
    if (showKhataAmounModal.isEdit) {
      if (accountDetails) {
        clickAccount(accountDetails);
      }
    } else {
      setSinglePaymentList((singlePaymentList) => [item, ...singlePaymentList]);
      totalAmountCalculet(item.amount_type, item.amount);
    }
    setShowKhataAmountModal({ data: null, type: 1, isOpen: false });
    setRefash(!refash);
  };

  const totalAmountCalculet = (type: number, amount: number) => {
    if (type === 1) {
      setGiveAmt(giveAmt + amount);
    } else {
      setGetAmt(getAmt + amount);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (
      scrollTop + clientHeight >= scrollHeight &&
      !loading &&
      paymentListPages < paymentListTotalPages
    ) {
      setLoading(true);
      setPaymentListPage(paymentListPages + 1);
    }
  };

  return (
    <div className="w-full px-5 grid md:grid-cols-2 gap-10 sm:gap-2 ">
      <div
        className={clsx(
          `space-y-2 min-h-[70vh] md:min-h-[87vh] mb-5 sm:mb-0 flex flex-col md:border-r sm:pr-4`,
          {
            hidden: showAccountDetails && isSmallScreen,
          },
        )}
      >
        {/* get give card */}
        <div className="flex justify-between gap-5 w-full">
          <div className="p-3 border flex flex-col gap-2 justify-center items-center rounded-lg w-full max-w-[15rem] text-sm sm:text-base">
            <p className="text-center text-green-600 font-bold">₹ {getAmt}</p>
            <p className="text-neutral-400">You will get</p>
          </div>
          <div className="flex justify-end w-full">
            <div className="p-3 border flex flex-col text-sm sm:text-base gap-2 justify-center items-center rounded-lg w-full max-w-[15rem]">
              <p className="text-center text-cardinal-600 font-bold">
                ₹ {giveAmt}
              </p>
              <p className="text-neutral-400">You will give</p>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center gap-1 pt-5">
          <Input
            type="text"
            value={searchParams || ""}
            onChange={(e) => setSearchParams(e.target.value)}
            className="flex-[4] text-sm sm:text-base shadow-none border-neutral-200 rounded-lg"
            placeholder="Search"
          />
        </div>

        <div className="relative h-full flex flex-col">
          <div className="flex-1 overflow-y-auto">
            <h3 className="font-bold sm:text-lg my-4">Amount</h3>
            <div
              className="max-h-[50vh] no-scrollbar overflow-y-auto"
              onScroll={handleScroll}
            >
              {paymentList.map((payment, index) => (
                <div
                  key={index}
                  onClick={() => clickAccount(payment)}
                  className="cursor-pointer mb-3"
                >
                  <KhataAmountDetailsCard
                    amount={payment.total_amount}
                    bussinessName={payment.company_name}
                    paymentStatus={
                      payment.amount_type === 1
                        ? KhataPaymentStatus.ToPay
                        : KhataPaymentStatus.ToAdvance
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center pb-2">
          <Button
            className="flex-[1] w-full bg-blue-500 hover:bg-blue-600 text-xs xs:text-base"
            onClick={toggleAddAccountModal}
          >
            Add New Account
          </Button>
        </div>
      </div>

      {showAccountDetails && (
        <div
          className={clsx(
            "md:flex space-y-2 md:space-y-0 md:gap-3 min-h-[70vh] sm:pl-2 md:flex-col",
            {
              hidden: !showAccountDetails,
            },
          )}
        >
          <div
            className={clsx("md:hidden ", {
              flex: showAccountDetails,
            })}
          >
            <Button
              variant="outline"
              className="rounded-full mb-3 items-center font-normal py-1"
              onClick={toggleAccountDetails}
            >
              <Undo2Icon size={12} />
              Back
            </Button>
          </div>
          {accountDetails !== null && (
            <>
              <div className="w-full">
                <KhataAccountDetailsCard
                  KhataId={accountDetails.id}
                  bussinessName={accountDetails.company_name}
                  mobileNumber={accountDetails.mobile_no}
                  name={accountDetails.contact_name}
                  clickEdit={(action) =>
                    setShowKhataEditAccountDetailsModal(action)
                  }
                  onFilter={toggleStatementFilterModal}
                />
              </div>
              <div className="space-y-2">
                <KhataPaymentStatusCard
                  amount={accountDetails?.total_amount}
                  paymentStatus={
                    accountDetails.amount_type === 1
                      ? KhataPaymentStatus.ToPay
                      : KhataPaymentStatus.ToAdvance
                  }
                />
              </div>
            </>
          )}
          <div className="relative h-full flex flex-col">
            <div className="flex-1 overflow-y-auto">
              <h3 className="font-bold sm:text-lg my-4">History</h3>
              <div className="max-h-[50vh] no-scrollbar overflow-y-auto space-y-2">
                {singlePaymentList.map((item, index) => {
                  if (item) {
                    return (
                      <KhataHistoryCard
                        amount={item?.amount || 0}
                        date={new Date(item.date)}
                        orderId={item.order_id}
                        paymentStatus={
                          item.amount_type === 1
                            ? KhataPaymentStatus.ToPay
                            : KhataPaymentStatus.ToAdvance
                        }
                        reason={item.reason}
                        id={item.khata_id}
                        onEdit={() =>
                          setShowKhataAmountModal({
                            data: item,
                            type: item.amount_type,
                            isOpen: true,
                            isEdit: true,
                            index,
                          })
                        }
                        key={index}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 p-4 bg-white shadow-top">
              <div className="flex justify-between gap-2 w-full">
                <Button
                  className="w-full max-w-xs bg-cardinal-600 hover:bg-cardinal-700 text-sm sm:text-base"
                  onClick={() =>
                    setShowKhataAmountModal({
                      data: null,
                      type: 1,
                      isOpen: true,
                    })
                  }
                >
                  You Pay
                </Button>
                <Button
                  className="w-full max-w-xs bg-green-600 hover:bg-green-700 text-sm sm:text-base"
                  onClick={() =>
                    setShowKhataAmountModal({
                      data: null,
                      type: 2,
                      isOpen: true,
                    })
                  }
                >
                  You Got
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      <KhataAddAccountModal
        isOpen={showAddAccountModal}
        onClose={toggleAddAccountModal}
        onSubmit={(data) => {
          setPaymentList([data, ...paymentList]);
          totalAmountCalculet(data.amount_type, data.total_amount);
          toggleAddAccountModal();
        }}
      />

      {accountDetails && (
        <KhataEditAccountDetailsModal
          isOpen={showKhataEditAccountDetailsModal}
          onClose={() => setShowKhataEditAccountDetailsModal(false)}
          bussinessName={accountDetails?.company_name}
          khata_id={accountDetails?.id}
          mobileNumber={accountDetails.mobile_no}
          name={accountDetails.contact_name}
          onSubmit={() => {
            clickAccount(accountDetails);
            setShowKhataEditAccountDetailsModal(false);
          }}
        />
      )}

      {showKhataAmounModal.isOpen && accountDetails ? (
        <>
          <KhataAmountHistoryModal
            isOpen={showKhataAmounModal.isOpen}
            onClose={() => {
              setShowKhataAmountModal({ data: null, type: 1, isOpen: false });
            }}
            onSubmit={onSubmitPayment}
            id={accountDetails?.id}
            isEdit={showKhataAmounModal.isEdit}
            type={showKhataAmounModal.type}
            item={showKhataAmounModal.data}
          />
        </>
      ) : null}

      {
        <KhataStatementFilterModal
          isOpen={showStatementFilterModal}
          onClose={toggleStatementFilterModal}
          onSubmit={setShowStatementFilterModalData}
        />
      }
    </div>
  );
};
export default KhataPage;
