import React, { useEffect, useState } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { GetCities } from "../../../lib/Service/MasterService";
import { CitieDataType } from "../../../lib/DataType/MasterDataType";
import { ErrorHandler } from "../../../lib/Utils/Common";

type Props = {
  onValueChange: (data: string[]) => void;
  selectedValues: string[];
  placeholder?: string;
  selectionLimit?: number;
};

const MultiCitySelect: React.FC<Props> = ({
  onValueChange,
  selectedValues,
  placeholder = "Select cities...",
  selectionLimit,
}) => {
  const [filter, setFilter] = useState<string>("");
  const [citiesList, setCitiesList] = useState<CitieDataType[]>([]);

  useEffect(() => {
    if (filter.length > 2) {
      GetCities(`pagination=50&search=${filter}`)
        .then((data) => {
          if (data.status) {
            setCitiesList(data.data.data);
          }
        })
        .catch(ErrorHandler);
    }
  }, [filter]);

  const handleSelect = (selectedList: CitieDataType[]) => {
    onValueChange(selectedList.map((city) => city.id.toString()));
  };

  const handleRemove = (selectedList: CitieDataType[]) => {
    onValueChange(selectedList.map((city) => city.id.toString()));
  };

  const inputClasses: string =
    "w-full bg-neutral-50 border-neutral-200 text-neutral-800";
  return (
    <div>
      <Multiselect
        options={citiesList}
        selectedValues={citiesList.filter((city) =>
          selectedValues.includes(city.id.toString()),
        )}
        onSelect={handleSelect}
        onRemove={handleRemove}
        displayValue="city"
        placeholder={placeholder}
        onSearch={setFilter}
        className={inputClasses}
        selectionLimit={selectionLimit}
        emptyRecordMsg="Please enter your city name"
        style={{
          searchBox: {
            borderRadius: 10,
            padding: 3,
            margin: 0,
            fontSize: "1rem",
            lineHeight: "1.25rem",
            color: "rgb(38 38 38 / var(--tw-text-opacity))",
          },
          optionContainer: {
            border: "1px solid #ccc",
            borderRadius: 7,
            padding: "0",
            margin: "0",
          },
          option: {
            padding: "5px 7px", // Increase padding for better spacing
            margin: "0",
            borderBottom: "1px solid #F2F5F9",
            cursor: "pointer",
          },
          inputField: {
            padding: "5px",
            margin: "0",
          },
          chips: {
            background: "#17a34a",
            color: "#fff",
            padding: "4px 6px",
            margin: "2px",
            borderRadius: "8px",
          },
          selectedValues: {
            backgroundColor: "#F2F5F9",
          },
        }}
      />
    </div>
  );
};

export default MultiCitySelect;
