import React from "react";

export interface BiltyFrightDetailsCardProps {
  frightType: number;
  amount: number;
  pricePerUnit: number;
  advance: number;
  remaining: number;
}

const BiltyFrightDetailsCard: React.FC<BiltyFrightDetailsCardProps> = ({
  ...props
}) => {
  return (
    <div className=" p-3 text-xs sm:text-sm">
      <div className="grid grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4">
        <div className="grid gap-3">
          <div className="">
            <p className="text-neutral-400">Fright Type</p>
            <p className="font-semibold">
              {props.frightType === 1 ? "To Pay" : "Advance"}
            </p>
          </div>
          <div className="text-left">
            <p className="text-neutral-400">Amount</p>
            <p className="font-semibold">₹ {props.amount}</p>
          </div>
        </div>
        {/* <div>
          <p className="text-neutral-400">Price/ Unit</p>
          <p className="font-semibold">₹ {props.pricePerUnit}</p>
        </div> */}
        <div className="text-left">
          <p className="text-neutral-400">Advance</p>
          <p className="font-semibold">₹ {props.advance}</p>
        </div>
        <div>
          <p className="text-neutral-400">Remaining</p>
          <p className="font-semibold">₹ {props.remaining}</p>
        </div>
      </div>
    </div>
  );
};

export default BiltyFrightDetailsCard;
