import { ErrorHandler } from "./Common";
import { MAIN_BASE_URL, TRANSPORTER_URL } from "./EnvVariables";

export const DownloadPDF = (endpoint: string): void => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("token") || "";
  myHeaders.append("Accept-Language", "te");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(`${MAIN_BASE_URL}${TRANSPORTER_URL}/${endpoint}`, requestOptions)
    .then(async (response: Response) => {
      if (!response.ok) {
        throw new Error(`Failed to fetch the PDF: ${response.statusText}`);
      }

      // Extract the filename from Content-Disposition
      const contentDisposition = response.headers.get("content-disposition");
      const fileNameMatch = contentDisposition?.match(/filename="(.+)"/);
      const fileName = fileNameMatch ? fileNameMatch[1] : "downloaded-file.pdf"; // Fallback to a default name
      response.headers.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      });

      // return response.blob(); // Convert response to a Blob
      return response.blob().then((blob) => ({ blob, fileName }));
    })
    .then(({ blob, fileName }) => {
      const pdfUrl = URL.createObjectURL(blob); // Create an object URL from the Blob
      // window.open(pdfUrl, "_blank"); // Open the PDF in a new tab

      // Create a link element
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = fileName; // Set the desired file name
      document.body.appendChild(link);
      link.click(); // Programmatically click the link to trigger the download

      // Clean up
      link.remove(); // Remove the link element

      // Optionally, revoke the object URL after usage
      setTimeout(() => URL.revokeObjectURL(pdfUrl), 10000); // Revoke after 10 seconds
    })
    .catch(ErrorHandler);
};
