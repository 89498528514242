import { ResListDataType } from "../DataType/ApiCommon";
import { CitieDataType, StateDataType } from "../DataType/MasterDataType";
import { MASTER_URL } from "../Utils/EnvVariables";
import HttpClient from "../Utils/HttpClient";

export const GetStates = (
  paramitar?: string,
): Promise<ResListDataType<StateDataType[]>> => {
  return HttpClient.get(`${MASTER_URL}/states?${paramitar}`);
};

export const GetCities = (
  paramitar?: string,
): Promise<ResListDataType<CitieDataType[]>> => {
  return HttpClient.get(`${MASTER_URL}/cities?${paramitar}`);
};
