import React, { useState } from "react";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import { CircleX, X } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { setTruckDetails } from "../../../store/CreateBiltySlice";
import { RootState } from "../../../store";
import {
  ErrorHandler,
  ValidateGSTNumber,
  ValidateLicenseNumber,
  ValidateNumber,
  ValidateVehicleNumber,
} from "../../../lib/Utils/Common";
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";
import {
  DriverDataType,
  FleetOwnersDataType,
} from "../../../lib/DataType/BiltyDataType";
import { GetDrivers, GetFleetOwners } from "../../../lib/Service/BiltyService";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const BiltyTruckDetailsModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const {
    driver_name,
    driver_number,
    fleet_owner,
    gst_number,
    license_number,
    vehicle_number,
  } = useSelector((state: RootState) => state.createBilty);

  const dispatch = useDispatch();
  const [vehicleNumber, setVehicleNumber] = useState(vehicle_number);
  const [driverName, setDriverName] = useState(driver_name);
  const [driverNumber, setDriverNumber] = useState(driver_number);
  const [licenseNumber, setLicenseNumber] = useState(license_number);
  const [fleetOwner, setFleetOwner] = useState(fleet_owner);
  const [fleetOwnerNumber, setFleetOwnerNumber] = useState<string>("");
  const [gstNumber, setGstNumber] = useState(gst_number);

  const [fleetOwners, setFleetOwners] = useState<FleetOwnersDataType[]>([]);
  const [drivers, setDrivers] = useState<DriverDataType[]>([]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !ValidateVehicleNumber(vehicleNumber) ||
      !ValidateGSTNumber(gstNumber) ||
      !ValidateLicenseNumber(licenseNumber)
    ) {
      return;
    }

    dispatch(
      setTruckDetails({
        driver_name: driverName,
        driver_number: driverNumber,
        fleet_owner: fleetOwner,
        gst_number: gstNumber,
        license_number: licenseNumber,
        vehicle_number: vehicleNumber,
      }),
    );

    onClose();
  };

  const search = (event: AutoCompleteCompleteEvent) => {
    GetFleetOwners(`search=${event.query}`)
      .then((res) => {
        if (res.status) {
          setFleetOwners(res.data.data);
        }
      })
      .catch(ErrorHandler);
  };

  const onSelectVehicle = (e: AutoCompleteChangeEvent) => {
    setVehicleNumber(e.value);

    const findData = fleetOwners.find((it) => it.vehicle_number === e.value);
    if (findData) {
      setGstNumber(findData.gst_number);
      setFleetOwnerNumber(findData.fleet_owner_number || "");
      setFleetOwner(findData.fleet_owner);
    }
  };

  const onSearchDriver = (event: AutoCompleteCompleteEvent) => {
    GetDrivers(`search=${event.query}`)
      .then((res) => {
        if (res.status) {
          setDrivers(res.data.data);
        }
      })
      .catch(ErrorHandler);
  };

  const onSelectDriver = (e: AutoCompleteChangeEvent) => {
    setDriverName(e.value);
    const driverData = drivers.find((it) => it.drive_name === e.value);
    if (driverData) {
      setDriverNumber(driverData.driver_number);
      setLicenseNumber(driverData.license_number);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md animate-in fade-in duration-200">
        <div className="flex justify-end pt-5 pr-5">
          <X
            onClick={onClose}
            size={20}
            className=" cursor-pointer text-neutral-700"
          />
        </div>
        <form className="space-y-3 p-5" onSubmit={handleSubmit}>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Vehicle Number
            </Label>
            <div className="relative w-full">
              <div className="card p-fluid">
                <AutoComplete
                  value={vehicleNumber}
                  suggestions={fleetOwners.map((it) => it.vehicle_number)}
                  completeMethod={search}
                  onChange={onSelectVehicle}
                  loadingIcon={() => <></>}
                  inputClassName="pl-3"
                  placeholder="Enter Vehicle Number"
                  className={`h-9 w-full  border rounded-md text-sm text-neutral-800 ${!ValidateVehicleNumber(vehicleNumber) ? "border-red-500" : "border-neutral-200"}`}
                />
              </div>
              {vehicleNumber !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setVehicleNumber("")}
                />
              )}
            </div>
            {!ValidateVehicleNumber(vehicleNumber) && (
              <small id="username-help" className="text-red-500">
                Please enter correct vehicle number
              </small>
            )}
          </div>

          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Fleet Owner
            </Label>
            <div className="relative w-full">
              <Input
                // required
                value={fleetOwner}
                onChange={(e) => setFleetOwner(e.target.value)}
                type="text"
                placeholder="Enter Owner Name"
                className="w-full border-neutral-200 text-sm text-neutral-800"
              />
              {fleetOwner !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setFleetOwner("")}
                />
              )}
            </div>
          </div>

          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Fleet Owner Number
            </Label>
            <div className="relative w-full">
              <Input
                // required
                value={fleetOwnerNumber}
                onChange={(e) => setFleetOwnerNumber(e.target.value)}
                type="number"
                placeholder="Enter Owner Number"
                className="w-full border-neutral-200 text-sm text-neutral-800"
              />
              {fleetOwner !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setFleetOwnerNumber("")}
                />
              )}
            </div>
          </div>

          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              GST Number
            </Label>
            <div className="relative w-full">
              <Input
                // required
                value={gstNumber}
                onChange={(e) => setGstNumber(e.target.value)}
                type="text"
                placeholder="Enter GST Number"
                errorTest="Please enter correct GST number"
                isError={!ValidateGSTNumber(gstNumber)}
                className={`w-full border-neutral-200 text-sm text-neutral-800 ${!ValidateGSTNumber(gstNumber) ? "border-red-500" : "border-neutral-200"}`}
              />
              {gstNumber !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setGstNumber("")}
                />
              )}
            </div>
          </div>

          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Driver Name
            </Label>
            <div className="relative w-full">
              <div className="card p-fluid">
                <AutoComplete
                  value={driverName}
                  suggestions={drivers.map((it) => it.drive_name)}
                  completeMethod={onSearchDriver}
                  onChange={onSelectDriver}
                  loadingIcon={() => <></>}
                  inputClassName="pl-3"
                  placeholder="Enter Driver Name"
                  className={`h-9 w-full  border rounded-md text-sm text-neutral-800 ${!ValidateVehicleNumber(vehicleNumber) ? "border-red-500" : "border-neutral-200"}`}
                />
              </div>
              {driverName !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setDriverName("")}
                />
              )}
            </div>
          </div>

          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              License Number
            </Label>
            <div className="relative w-full">
              <Input
                required
                value={licenseNumber}
                onChange={(e) => setLicenseNumber(e.target.value)}
                type="text"
                placeholder="Enter License Number"
                // className="w-full border-neutral-200 text-sm text-neutral-800"
                className={`w-full border text-sm text-neutral-800 ${!ValidateLicenseNumber(licenseNumber) ? "border-red-500" : "border-neutral-200"}`}
              />
              {licenseNumber !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setLicenseNumber("")}
                />
              )}
            </div>
            {!ValidateLicenseNumber(licenseNumber) && (
              <small id="username-help" className="text-red-500">
                please enter correct license number
              </small>
            )}
          </div>

          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Driver Number
            </Label>
            <div className="relative w-full">
              <Input
                required
                value={driverNumber}
                onChange={(e) => setDriverNumber(e.target.value)}
                type="text"
                placeholder="Enter Driver Number"
                className="w-full border-neutral-200 text-sm text-neutral-800"
              />
              {driverNumber !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setDriverNumber("")}
                />
              )}
            </div>
            {!ValidateNumber(driverNumber) && (
              <small id="username-help" className="text-red-500">
                please enter a valid 10 digit mobile number
              </small>
            )}
          </div>

          <div className="flex justify-center items-center pt-4">
            <Button
              className="bg-cardinal-600 w-24 sm:w-full sm:max-w-[15rem] hover:bg-cardinal-600"
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BiltyTruckDetailsModal;
