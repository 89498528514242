import { Pencil, Trash2 } from "lucide-react";
import { Separator } from "../../ui/separator";
import BiltyEditConsignmentModal from "../modals/bilty-edit-consignment-modal";
import { useState } from "react";

export interface BiltyCreateConsignmentDetailsCardProps {
  consignor: string;
  cosignee: string;
  totalItems: number | string;
  remaining: number | string;
}

export default function BiltyCreateConsignmentDetailsCard({
  ...props
}: BiltyCreateConsignmentDetailsCardProps) {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const toggleModal = () => {
    setShowEditModal((prev) => !prev);
  };

  return (
    <div className=" p-3 flex bg-gray-100 rounded-md">
      <div className="p-2 space-y-1 bg-white rounded-md text-xs max-w-xs">
        <div className="flex justify-between gap-3">
          <div className="space-y-1">
            <p className="text-neutral-400">CONSIGNOR</p>
            <p className="font-medium">{props.consignor}</p>
          </div>
          <div className="flex gap-2">
            {/* <Pencil
              size={16}
              className="text-blue-500 cursor-pointer"
              onClick={toggleModal}
            />
            <Trash2 size={16} className="text-cardinal-600 cursor-pointer" /> */}
          </div>
        </div>
        <Separator className="bg-neutral-200" />
        <div className="space-y-1">
          <p className="text-neutral-400">CONSIGNEE</p>
          <p className="font-medium">{props.cosignee}</p>
        </div>
        <Separator className="bg-neutral-200" />
        <div className="flex items-center gap-10 justify-between">
          <div className="space-y-1">
            <p className="text-neutral-400">TOTAL ITEMS</p>
            <p className="font-medium">{props.totalItems}</p>
          </div>
          <div className="space-y-1">
            <p className="text-neutral-400">REMAINING</p>
            <p className="font-medium">{props.remaining}</p>
          </div>
        </div>
        <BiltyEditConsignmentModal
          isOpen={showEditModal}
          onClose={toggleModal}
          {...props}
        />
      </div>
      <div className="flex pl-3 gap-3 flex-col justify-center items-center">
              <div
                className="cursor-pointer rounded-full p-1.5 flex items-center justify-center text-white bg-blue-600 hover:bg-blue-500 shadow-none transition-all duration-200 ease-out"
              >
                <Pencil className="h-3 w-3" />
              </div>
              <div
                className="cursor-pointer rounded-full p-1.5 flex items-center justify-center text-white bg-cardinal-600 hover:bg-cardinal-500 shadow-none transition-all duration-200 ease-out"
              >
                <Trash2 className="h-3 w-3" />
              </div>
      </div>
    </div>
  );
}
