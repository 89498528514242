import { DatePicker } from 'antd';
import React from 'react';
import dayjs from 'dayjs';

export interface InputDatePickerProps {
  date?: Date;
  placeholder?: string;
  name?: string;
  onChange?: (data: Date) => void;
  error?: boolean;
}

const InputDatePicker: React.FC<InputDatePickerProps> = ({
  date,
  placeholder,
  name,
  onChange,
  error
}) => {
  return (
    <DatePicker
      className="w-full h-9"
      value={dayjs(date)}
      status={error ? 'error' : undefined}
      placeholder={placeholder}
      name={name}
      onChange={(value) => {
        if (value !== null && onChange) {
          console.log(value.toDate());
          // console.log('value', new Date(value).toString(), dateString);
          onChange(new Date(value.toDate()));
        }
      }}
    />
  );
};

export default InputDatePicker;
