import { Link } from "react-router-dom";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Settings, Search, SlidersHorizontal, Plus } from "lucide-react";
import { Label } from "../../components/ui/label";
import BiltyFilterModal from "../../components/app/modals/BiltyFilterModal";
import BiltySettingModal from "../../components/app/modals/bilty-setting-modal";
import React, { useEffect, useState } from "react";
import { BiltyListDataType } from "../../lib/DataType/BiltyDataType";
import BiltyCard from "../../components/app/cards/bilty-card";
import { GetBiltyListService } from "../../lib/Service/BiltyService";
import { ErrorHandler } from "../../lib/Utils/Common";

interface filterDataType {
  date: string;
  from_city_id: string;
  to_city_id: string;
}

interface BiltyPageProps {}

const BiltyPage: React.FC<BiltyPageProps> = () => {
  const [searchParams, setSearchParams] = useState<string>("");
  const [pages, setPages] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false);
  const [biltyList, setBiltyList] = useState<BiltyListDataType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageloading, setPageLoading] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<filterDataType>({
    date: "",
    from_city_id: "",
    to_city_id: "",
  });

  const toggleFilterModal = () => {
    setShowFilterModal((prev) => !prev);
  };

  const toggleSettingModal = () => {
    setShowSettingModal((prev) => !prev);
  };

  useEffect(() => {
    console.log(filterData);
    GetBiltyListService(
      `search=${searchParams}&pagination=10&page=${pages}&from_city_id=${filterData.from_city_id}&to_city_id=${filterData.to_city_id}&date=${filterData.date}`,
    )
      .then((data) => {
        if (data.status) {
          setBiltyList(data.data.data);
          setPages(data.data.pagination.current_page);
          setTotalPages(data.data.pagination.last_page);
          setLoading(false);
        }
      })
      .catch(ErrorHandler)
      .finally(() => setPageLoading(true));
  }, [searchParams, pages, filterData]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (
      scrollTop + clientHeight >= scrollHeight &&
      !loading &&
      pages < totalPages
    ) {
      setLoading(true);
      setPages(pages + 1);
    }
  };

  return (
    <div className="w-full mb-10">
      <div className="px-5 flex flex-col sm:flex-row sm:justify-between items-center gap-2">
        <div className="relative w-full">
          <Label htmlFor="search">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-neutral-400 h-4 w-4" />
          </Label>
          <Input
            placeholder="Search"
            id="search"
            name="search"
            value={searchParams}
            onChange={(e) => setSearchParams(e.target.value)}
            className="pl-9 pr-4 h-10 shadow-none border rounded-md w-full"
          />
        </div>

        <div className="flex justify-end md:justify-normal w-full">
          <div className="grid w-full sm:flex items-center gap-3">
            <Link to="/bilty/create">
              <Button className="bg-cardinal-500 hover:bg-cardinal-600 text-white font-poppins px-6 pl-8 pr-9 h-10 rounded-lg w-full">
                <Plus size={40} />
                Add New Bilty
              </Button>
            </Link>
            <div className="flex w-full gap-3">
              <Button
                className="shadow-none w-full hover:bg-neutral-100 border border-neutral-200/30 flex items-center bg-neutral-100 rounded-lg font-poppins px-6 pl-8 pr-9 h-10 gap-2"
                onClick={toggleFilterModal}
              >
                <SlidersHorizontal size={16} className=" text-cardinal-500" />
                <span className="text-cardinal-500">Filter</span>
              </Button>
              <Button
                className="font-poppins shadow-none w-full px-6 pl-8 pr-9 h-10 gap-2 text-cardinal-500 bg-neutral-100 border border-neutral-200/30 hover:bg-neutral-100 rounded-lg"
                onClick={toggleSettingModal}
              >
                <Settings className="h-6 w-6 text-cardinal-500" />
                Bilty Setting
              </Button>
            </div>
          </div>
        </div>
      </div>

      {!pageloading ? null : biltyList.length > 0 ? (
        <div className="space-y-4 px-5 pt-5">
          <h3 className="font-bold text-lg sm:text-2xl">Bilty</h3>
          <div
            className="grid gap-5 max-h-[62vh] md:grid-cols-2 overflow-y-auto"
            onScroll={handleScroll}
          >
            {biltyList.map((bilty, i) => (
              <BiltyCard
                key={i}
                date={new Date(bilty.date)}
                from={bilty.from_city}
                // fromLocation={bilty.from_city}
                lrNumber={bilty.lr_bilty_number}
                items={Number(bilty.order_count)}
                status={bilty.status}
                to={bilty.to_city}
                id={bilty.id}
                // toLocation={bilty.to_city}
                vehicleNumber={bilty.vehicle_number}
                consignee_consignors={bilty.consignee_consignors}
              />
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className="min-h-[30vh] flex justify-center items-end">
            <div className="space-y-2">
              <div className="flex justify-center">
                <Link to="/bilty/create">
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-10 w-10 bg-cardinal-600 hover:bg-cardinal-600 rounded-full"
                  >
                    <Plus className="h-5 w-5 text-white" />
                  </Button>
                </Link>
              </div>
              <p className="text-center font-bold">Add your first Bilty</p>
            </div>
          </div>

          <div className="flex  justify-between items-center px-5 gap-5">
            <img
              src="/bilty/bilty-1.png"
              className="h-[9rem] sm:h-[12rem] md:h-[16rem] lg:h-[20rem]"
              alt="bilty 1"
            />
            <img
              src="/bilty/bilty-2.png"
              className="h-[9rem] sm:h-[12rem] md:h-[16rem] lg:h-[20rem]"
              alt="bilty 2"
            />
          </div>
        </>
      )}

      <BiltyFilterModal
        isOpen={showFilterModal}
        onClose={toggleFilterModal}
        onSubmit={setFilterData}
      />
      <BiltySettingModal
        isOpen={showSettingModal}
        onClose={toggleSettingModal}
      />
    </div>
  );
};

export default BiltyPage;
