import { Link } from "react-router-dom";
import { ConsignorConsigneeInfoDataType } from "../../../lib/DataType/BiltyDataType";

export enum BiltyStatus {
  Pending = "Pending",
  Progress = "In Progress",
  Delivered = "Delivered",
  Cancelled = "Cancelled",
}

export interface BiltyCardProps {
  lrNumber: string;
  items: number;
  date: Date;
  vehicleNumber: string;
  from: string;
  status: string;
  to: string;
  // fromLocation: string;
  // toLocation: string;
  id: string | number;
  consignee_consignors: ConsignorConsigneeInfoDataType[];
}

function BiltyCard({ ...props }: BiltyCardProps) {
  return (
    <Link
      to={`/bilty/${props.id}/details`}
      className="flex flex-col text-xs sm:text-sm space-y-3 shadow-lg border border-neutral-200 rounded-lg"
    >
      <div className="w-full flex justify-between items-center px-4 pt-2">
        <div className="flex gap-1">
          <p>LR No:</p>
          <p className="font-bold">{props.lrNumber}</p>
        </div>
        <div className="flex gap-1 items-center">
          <p className="font-bold">Items</p>
          <p className="bg-cardinal-600 px-2 py-0.5 text-white rounded-md">
            {props.items}
          </p>
        </div>
      </div>
      <div className="w-full flex justify-between items-center px-4">
        <div className="flex gap-1">
          <p className="font-bold">Bilty Date:</p>
          <p className="text-cardinal-600 font-bold">
            {props.date.getDate()}{" "}
            {props.date.toLocaleString("default", { month: "short" })}{" "}
            {props.date.getFullYear()}
          </p>
        </div>
        <div className="flex gap-1">
          <p>Vehicle No:</p>
          <p className="text-cardinal-600 font-bold">{props.vehicleNumber}</p>
        </div>
      </div>
      <div className="flex flex-col px-4">
        <img src="/home/bilty-card.svg" alt="bilty" />
        <div className="flex items-center justify-between">
          <p className="font-medium">{props.from}</p>
          {/* todo:  change the bgcolor acc to status depend  */}
          {/* <p className="bg-green-600 rounded-md py-0.5 text-white px-2">
            {props.status}
          </p> */}
          <p className="font-medium">{props.to}</p>
        </div>
      </div>
      {props.consignee_consignors.length > 0 && (
        <div className="bg-neutral-200/30 px-3 py-2 rounded-b-md flex justify-between items-center">
          <p>
            {props.consignee_consignors[0].consignee_name}{" "}
            {props.consignee_consignors.length > 1 && (
              <span className="text-cardinal-500 font-medium">
                +{props.consignee_consignors.length - 1}
              </span>
            )}
          </p>
          <p>
            {props.consignee_consignors[0].consignor_name}{" "}
            {props.consignee_consignors.length > 1 && (
              <span className="text-cardinal-500 font-medium">
                +{props.consignee_consignors.length - 1}
              </span>
            )}
          </p>
        </div>
      )}
    </Link>
  );
}

export default BiltyCard;
