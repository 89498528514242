import { PaginationDataType } from "./ApiCommon";

export interface KhataItemDataType {
  id: number;
  order_id: number;
  company_name: string;
  contact_name: string;
  mobile_no: string;
  amount_type: number;
  total_amount: number;
  updated_at: string;
}

export interface GetKhataDataType {
  give_amt: number;
  get_amt: number;
  data: KhataItemDataType[];
  pagination: PaginationDataType;
}

export interface KhataTransactionDataType {
  id: number;
  khata_id: number;
  order_id: number | null;
  amount_type: number;
  amount: number;
  date: string; // ISO date format (e.g., "YYYY-MM-DD")
  reason: string;
  updated_at: string; // ISO date-time format (e.g., "YYYY-MM-DDTHH:mm:ss.sssZ")
}

export interface GetKhataAmountDataType {
  khata_account: KhataItemDataType;
  transaction: {
    data: KhataTransactionDataType[];
    pagination: PaginationDataType;
  };
}

export enum KhataPaymentStatus {
  ToPay = "To Pay",
  ToAdvance = "To Advance",
}

export interface CreateKhataAccountDataType {
  company_name: string;
  contact_name: string;
  mobile_no: number;
  amount_type: number;
  total_amount: number;
  reason?: string;
}

export interface UpdateKhataAccountDataType {
  company_name: string;
  contact_name: string;
  mobile_no: number;
  khata_id: number;
}

export interface KhataAmountRequestDataType {
  order_id: number | null;
  amount_type: number;
  amount: number;
  date: string;
  reason: string;
}
