import React from 'react';
// import { Input } from "../../ui/input";
import InputDatePicker from '../date-picker';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDate,
  setFromCityId,
  setToCityId
  // setVehicleNumber,
} from '../../../store/CreateBiltySlice';
import moment from 'moment';
import { RootState } from '../../../store';
import { Input } from '../../ui/input';
import CitySelectInput from './CitySelectInput';

const BiltyCreateGeneratingForm: React.FC = () => {
  const { from_city_id, to_city_id, date, lr_bilty_number } = useSelector(
    (state: RootState) => state.createBilty
  );
  const dispatch = useDispatch();

  const inputClasses: string = 'border border-neutral-200';
  const labelClasses: string = `self-center font-normal w-1/4`;
  return (
    <div className="space-y-2 text-sm sm:text-base">
      <div className="flex gap-4">
        <label className={labelClasses}>Bilty Number</label>
        <Input
          type="text"
          className={inputClasses}
          required
          name="lr_bilty_number"
          placeholder="Bilty Number"
          value={lr_bilty_number}
        />
      </div>
      <div className="flex gap-4">
        <label className={labelClasses}>Date</label>
        <InputDatePicker
          date={date === '' ? undefined : new Date(date)}
          onChange={(e) => {
            dispatch(setDate(moment(e).format('YYYY/MM/DD').toString()));
          }}
        />
      </div>

      <div className="flex gap-4">
        <label className={labelClasses}>From City</label>
        <CitySelectInput
          value={from_city_id}
          onValueChange={(e) => dispatch(setFromCityId(e))}
          name="from"
          required
          placeholder=""
        />
      </div>
      <div className="flex gap-4">
        <label className={labelClasses}>To City</label>
        <CitySelectInput
          value={to_city_id}
          onValueChange={(e) => dispatch(setToCityId(e))}
          name="to"
          required
          placeholder=""
        />
      </div>
    </div>
  );
};

export default BiltyCreateGeneratingForm;
