import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import { ChevronLeft } from "lucide-react";
import InputDatePicker from "../date-picker";
import { ModalProps } from "../../../lib/definitions";
import MultiCitySelect from "../forms/MultiCitySelect";
import { FormatDate } from "../../../lib/Utils/Common";

export interface BiltyFilterFormData {
  date: Date | undefined;
  pickUpCity: string;
  deliveryCity: string;
}

const BiltyFilterModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [pickUpCity, setPickUpCity] = useState<string[]>([]);
  const [deliveryCity, setDeliveryCity] = useState<string[]>([]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    if (onSubmit) {
      onSubmit({
        date: FormatDate(formData.get("date") as string),
        to_city_id: pickUpCity,
        from_city_id: deliveryCity,
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div
        ref={modalRef}
        className="bg-white rounded-lg shadow-lg w-full max-w-md animate-in fade-in duration-200"
      >
        <div className="flex items-center p-4 border-b">
          <button
            onClick={onClose}
            className="p-1 hover:bg-neutral-100 rounded-full"
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
          <h2 className="text-xl font-semibold flex-1 text-center mr-6">
            Filter
          </h2>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <Label htmlFor="date" className="text-xs">
              Date
            </Label>
            <InputDatePicker name="date" />
          </div>
          <div>
            <Label htmlFor="pickUpCity" className="text-xs">
              Pick Up City
            </Label>
            <MultiCitySelect
              onValueChange={setPickUpCity}
              selectedValues={pickUpCity}
              placeholder="Select cities..."
            />
          </div>
          <div className="">
            <Label htmlFor="deliveryCity" className="text-xs">
              Delivery City
            </Label>
            <MultiCitySelect
              onValueChange={setDeliveryCity}
              selectedValues={deliveryCity}
              placeholder="Select cities..."
            />
          </div>

          <Button
            type="submit"
            className="w-full bg-cardinal-500 hover:bg-cardinal-600 text-white py-3 rounded-lg"
          >
            APPLY
          </Button>
        </form>
      </div>
    </div>
  );
};

export default BiltyFilterModal;
