import * as React from "react";
import { cn } from "../../lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
  errorTest?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, errorTest = "Please enter correct details", ...props },
    ref,
  ) => {
    return (
      <div className="w-full">
        <input
          type={type}
          className={cn(
            "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
            className,
          )}
          ref={ref}
          {...props}
        />
        {props.isError && (
          <small id="username-help" className="text-red-500 absolute pb-4">
            {errorTest}
          </small>
        )}
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input };
